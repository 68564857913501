import axios from 'axios'

export default {
  createChatovateCampaign (data) {
    return axios.post(
      '/chatovate/campaigns?start_date=' + data.startDate + '&end_date=' + data.endDate + '&apr=' + data.apr + '&apr_operator=' + data.aprOperator + '&payment=' + data.payment +
      '&payment_operator=' + data.paymentOperator + '&months_remaining=' + data.paymentsRemaining + '&months_remaining_operator=' + data.paymentsRemainingOperator +
      '&months_since_sale=' + data.monthsSinceSale + '&months_since_sale_operator=' + data.monthsSinceSaleOperator +
      '&term_complete_percent=' + data.termCompletePercent + '&term_complete_percent_operator=' + data.termCompletePercentOperator +
      '&make=' + data.makes + '&model=' + data.models + '&year=' + data.years + '&states=' + data.states + '&conditions=' + data.conditions +
      '&header_text=' + data.headerText + '&header_values=' + data.headerValues +
      '&initial_text_message=' + data.initialTextMessage +
      '&second_text_message=' + data.secondTextMessage +
      '&third_text_message=' + data.thirdTextMessage +
      '&days_to_wait_for_second_text=' + data.daysToWaitForSecondText +
      '&days_to_wait_for_third_text=' + data.daysToWaitForThirdText +
      '&name=' + data.name + '&users=' + data.users +
      '&max_new_leads_per_day=' + data.maxNewLeadsPerDay,
      '&start_last_serviced_date=' + data.startLastServicedDate +
      '&end_last_serviced_date=' + data.endLastServiceDate +
      '&op_codes=' + data.opCodes +
      '&mission_type=' + data.missionType
    )
  },
  updateChatovateCampaign (data) {
    return axios.patch(
      '/chatovate/campaigns/' + data.campaignId + '?start_date=' + data.startDate + '&end_date=' + data.endDate + '&apr=' + data.apr + '&apr_operator=' + data.aprOperator + '&payment=' + data.payment +
      '&payment_operator=' + data.paymentOperator + '&months_remaining=' + data.paymentsRemaining + '&months_remaining_operator=' + data.paymentsRemainingOperator +
      '&months_since_sale=' + data.monthsSinceSale + '&months_since_sale_operator=' + data.monthsSinceSaleOperator +
      '&term_complete_percent=' + data.termCompletePercent + '&term_complete_percent_operator=' + data.termCompletePercentOperator +
      '&make=' + data.makes + '&model=' + data.models + '&year=' + data.years + '&states=' + data.states + '&conditions=' + data.conditions +
      '&header_text=' + data.headerText + '&header_values=' + data.headerValues +
      '&initial_text_message=' + data.initialTextMessage +
      '&second_text_message=' + data.secondTextMessage +
      '&third_text_message=' + data.thirdTextMessage +
      '&days_to_wait_for_second_text=' + data.daysToWaitForSecondText +
      '&days_to_wait_for_third_text=' + data.daysToWaitForThirdText +
      '&name=' + data.name + '&users=' + data.users +
      '&max_new_leads_per_day=' + data.maxNewLeadsPerDay + 
      '&start_last_serviced_date=' + data.startLastServicedDate +
      '&end_last_serviced_date=' + data.endLastServiceDate +
      '&op_codes=' + data.opCodes +
      '&mission_type=' + data.missionType
    )
  },
  getChatovateCampaign (campaignId) {
    return axios.get('/chatovate/campaigns/' + campaignId)
  },
  getAllChatovateCampaigns () {
    return axios.get('/chatovate/campaigns/')
  },
  deleteChatovateCampaign (campaignId) {
    return axios.delete(
      'chatovate/campaigns/' + campaignId,
    )
  },
  getCustomers (campaignId) {
    return axios.get('/chatovate/campaigns/' + campaignId + '/customers/')
  },
  deleteCustomer (campaignId, customerId) {
    return axios.delete('/chatovate/campaigns/' + campaignId + '/customers/' + customerId)
  },
  getCustomer (campaignId, customerId) {
    return axios.get('/chatovate/campaigns/' + campaignId + '/customers/' + customerId)
  },
  markMessagesRead (campaignId, customerId) {
    return axios.post('/chatovate/campaigns/' + campaignId + '/customers/' + customerId + '/mark_message_read')
  },
  sendTextMessage (campaignId, customerId, message, force) {
    return axios.post('/chatovate/campaigns/' + campaignId + '/customers/' + customerId + '/send_text_message?message=' + message + '&force=' + force)
  },
  queueTextMessage (campaignId, customerId, message) {
    return axios.post('/chatovate/campaigns/' + campaignId + '/customers/' + customerId + '/queue_text_message?message=' + message)
  },
  updateOptOut (campaignId, customerId, value) {
    return axios.post('/chatovate/campaigns/' + campaignId + '/customers/' + customerId + '/update_opt_out?value=' + value)
  },
  deleteQueuedMessage (campaignId, customerId, queuedMessageId) {
    return axios.delete('/chatovate/campaigns/' + campaignId + '/customers/' + customerId + '/queued_messages/' + queuedMessageId)
  },
  getChatovatePhoneSetting () {
    return axios.get('/chatovate/settings/')
  },
  registerPhoneNumber () {
    return axios.post('/chatovate/settings/register_phone_number')
  },
  registerServicePhoneNumber () {
    return axios.post('/chatovate/settings/register_service_phone_number')
  },
  updateChatovatePhoneSetting (data) {
    return axios.post('/chatovate/settings?texting_start_time=' + data.textingStartTime + '&texting_end_time=' + data.textingEndTime +
    '&texting_enabled=' + data.textingEnabled + '&texting_days_of_week=' + data.textingDaysOfWeek +
    '&number_texts_for_email_alert=' + data.numberTextsForEmailAlert + '&email_alerts_disabled=' + data.emailAlertsDisabled +
    '&voice_forwarding_number=' + data.voiceForwardingNumber +
    '&service_forwarding_number=' + data.serviceVoiceForwardingNumber +  
    '&number_minutes_for_text_alert=' + data.numberMinutesForTextAlert +
    '&text_alerts_disabled=' + data.textAlertsDisabled +
    '&crm_address=' + data.crmAddress)
  },
  getQueuedMessages (campaignId, customerId) {
    return axios.get('/chatovate/campaigns/' + campaignId + '/customers/' + customerId + '/queued_messages')
  },
  sendToCrm (campaignId, customerId) {
    return axios.post('/chatovate/campaigns/' + campaignId + '/customers/' + customerId + '/send_to_crm')
  },
  saveComments (campaignId, customerId, comments) {
    return axios.post('/chatovate/campaigns/' + campaignId + '/customers/' + customerId + '/save_comments?comments=' + comments)
  },
  saveApptDate (campaignId, customerId, apptDate, apptComments) {
    return axios.post('/chatovate/campaigns/' + campaignId + '/customers/' + customerId + '/save_appt_date?appt_date=' + apptDate + '&appt_comments=' + apptComments)
  },
  clearApptDate (campaignId, customerId) {
    return axios.post('/chatovate/campaigns/' + campaignId + '/customers/' + customerId + '/clear_appt_date')
  },
  setLost (data) {
    return axios.post('/chatovate/campaigns/' + data.campaignId + '/customers/' + data.customerId + '/set_lost?recycle_date=' + data.recycleDate)
  },
  clearLost (data) {
    return axios.post('/chatovate/campaigns/' + data.campaignId + '/customers/' + data.customerId + '/clear_lost')
  },
  getAccountStats () {
    return axios.get('/chatovate/account_stats')
  },
  getWaitingOnUs () {
    return axios.get('/chatovate/waiting_on_us')
  },
  getWaitingOnThem () {
    return axios.get('/chatovate/waiting_on_them')
  },
  getSaveAttempts () {
    return axios.get('/chatovate/save_attempts')
  },
  createSaveAttempt (data) {
    return axios.post('/chatovate/save_attempts?days=' + data.days + '&message=' + data.message)
  },
  updateSaveAttempt (data) {
    return axios.patch('/chatovate/save_attempts/' + data.id + '?days=' + data.days + '&message=' + data.message)
  },
  deleteSaveAttempt (id) {
    return axios.delete(
      'chatovate/save_attempts/' + id,
    )
  },
  updateProfile (data) {
    return axios.patch('/api/v1/users/' + data.id + '?first_name=' + data.firstName + '&last_name=' + data.lastName + '&email=' + data.email + '&phone=' + data.phone)
  },
  resetPassword (email) {
    return axios.post('/api/v1/password_resets?email=' + email)
  },
  getPasswordReset (token, email) {
    return axios.get('/api/v1/password_resets?token=' + token + '&email=' + email)
  },
  updatePassword (token, email, pw) {
    return axios.patch('/api/v1/update_password?token=' + token + '&email=' + email + '&pw=' + pw)
  },
  getUserInvite (token, email) {
    return axios.get('/api/v1/user_invites?token=' + token + '&email=' + email)
  },
  createUserFromInvite (data) {
    return axios.post('/api/v1/create_user_from_invite?account_uuid=' + data.accountId + '&token=' + data.token + '&email=' + data.email + '&first_name=' + data.firstName + '&last_name=' + data.lastName + '&phone=' + data.phone + '&pw=' + data.pw)
  },
  getAppointments () {
    return axios.get('/chatovate/appointments/')
  },
  getLost () {
    return axios.get('/chatovate/lost/')
  },
  getMatchbackResults (campaignId) {
    return axios.get('/chatovate/campaigns/' + campaignId + '/matchback_results/')
  },
  pauseMission (campaignId) {
    return axios.post('/chatovate/campaigns/' + campaignId + '/pause_mission/')
  },
  resumeMission (campaignId) {
    return axios.post('/chatovate/campaigns/' + campaignId + '/resume_mission/')
  },
  getRecentCustomerMessages () {
    return axios.get('/chatovate/recent_customer_messages/')
  },
}
