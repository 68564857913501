
<template>
  <div>
    <v-menu
      ref="menu"
      v-model="dropdownOpen"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="model"
      transition="scale-transition"
      offset-y
      max-width="560px"
      min-width="560px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="model"
          :label="label"
          readonly
          outlined
          v-on="on"
        />
      </template>

      <div class="v-date-time-widget-container">
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-date-picker
              v-model="dateModel"
              width="240"
              color="primary"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-time-picker
              v-if="dropdownOpen"
              v-model="timeModel"
              ampm-in-title
              format="ampm"
              color="primary"
              width="240"
            />
          </v-col>

          <v-col
            cols="12"
            class="text-center"
          >
            <v-btn
              small
              color="primary"
              @click="confirm()"
            >
              Ok
            </v-btn>
            <v-btn
              small
              @click="dropdownOpen = false"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-menu>
  </div>
</template>

<script>
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  export default {
    name: 'DateTime',
    mixins: [formatterMixin],
    props: {
      value: {
        type: String,
        default: () => (null),
      },
      label: {
        type: String,
        default: () => ('Select time and date'),
      },
    },
    data () {
      return {
        dropdownOpen: false,
        dateModel: '',
        timeModel: '',
        filled: false,
      }
    },

    computed: {
      model: {
        get () {
          var d = this.dateModel + 'T' + this.timeModel
          return this.formatIsoDate(d, true)
        },
        set (model) {
          this.filled = true
        },
      },
      displayDate: {
        get () {
          if (this.filled) {
            var d = this.dateModel + 'T' + this.timeModel
            return this.formatIsoDate(d, true)
          } else {
            return ''
          }
        },
        set (displayDate) {},
      },
    },

    mounted () {
      // Set the current date and time as default value
      var d = new Date()
      var currentHour = d.getHours()
      var minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()
      var currentTime = currentHour + ':' + minutes
      this.timeModel = currentTime
      this.dateModel = d.toISOString().substring(0, 10)
      this.$emit('changedApptDate', this.model)
    },

    methods: {
      // Confirm the datetime selection and close the popover
      confirm () {
        this.onUpdateDate()
        this.dropdownOpen = false
      },

      // Format the date and trigger the input event
      onUpdateDate () {
        if (!this.dateModel || !this.timeModel) return false
        this.displayDate = this.dateModel + 'T' + this.timeModel
        this.$emit('changedApptDate', this.model)
      },
    },
  }
</script>
<style scoped>
.v-date-time-widget-container{
    background: white;padding:15px
}

.v-card{
  box-shadow: none
}

.btn-am{
  float:left;
}

.btn-pm{
  float:right
}

.v-date-picker-table{
  height: auto;
}
</style>
