<template>
  <v-container
    fluid
  >
    <template v-if="customer">
      <div
        class="text-center display-3 mb-3"
      >
        {{ getPageTitle() }}
        <v-icon
          slot="icon"
          color="primary"
          size="36"
          @click="showCustomerInfoDialog=true"
        >
          mdi-information
        </v-icon>
      </div>
      <template v-if="unreadMessages">
        <div class="text-center">
          <v-alert
            type="warning"
            color="#FFB001"
          >
            <span class="display-1 font-weight-black">
              This has customer has <strong>{{ numberUnreadMessages }}</strong> unread messages.
            </span>
          </v-alert>
        </div>
      </template>
      <v-list-item-group
        v-model="phases"
        active-class="primary--text"
        multiple
      >
        <template>
          <v-row dense>
            <v-col
              sm="12"
              md="12"
              lg="3"
              xl="3"
            >
              <v-sheet
                class="mx-auto"
                elevation="8"
                rounded
              >
                <v-list-item
                  active-class="primary--text"
                  disabled
                >
                  <v-list-item-content>
                    <v-btn
                      absolute
                      bottom
                      color="primary"
                      right
                      fab
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-list-item-icon>
                      <v-icon
                        x-large
                        color="primary"
                      >
                        mdi-radio-tower
                      </v-icon>
                      <div class="display-2 font-weight-black text-center mt-2 ml-3">
                        Attempting Contact
                      </div>
                    </v-list-item-icon>
                  </v-list-item-content>
                </v-list-item>
              </v-sheet>
            </v-col>
            <v-col
              sm="12"
              md="12"
              lg="2"
              xl="2"
            >
              <v-sheet
                class="mx-auto"
                elevation="8"
                rounded
              >
                <v-list-item
                  :active-class="getContactedTextColor()"
                  disabled
                >
                  <v-list-item-content>
                    <template v-if="customer.status === 'Contacted'">
                      <v-btn
                        absolute
                        bottom
                        color="primary"
                        right
                        fab
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                    <v-list-item-icon>
                      <v-icon
                        x-large
                        :color="getContactedIconColor()"
                      >
                        mdi-human-greeting-variant
                      </v-icon>
                      <div class="display-2 font-weight-black text-center mt-2 ml-3">
                        Contacted
                      </div>
                    </v-list-item-icon>
                  </v-list-item-content>
                </v-list-item>
              </v-sheet>
            </v-col>
            <v-col
              sm="12"
              md="12"
              lg="2"
              xl="2"
            >
              <v-sheet
                class="mx-auto"
                elevation="8"
                rounded
              >
                <v-list-item
                  :active-class="getSentToCrmTextColor()"
                  disabled
                >
                  <v-list-item-content>
                    <template v-if="customer.sent_to_crm_at">
                      <v-btn
                        absolute
                        bottom
                        color="primary"
                        right
                        fab
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                    <v-list-item-icon>
                      <v-icon
                        x-large
                        :color="getSentToCrmIconColor()"
                        left
                      >
                        mdi-account-group
                      </v-icon>
                      <div class="display-2 font-weight-black text-center mt-2 ml-3">
                        Sent to CRM
                      </div>
                    </v-list-item-icon>
                  </v-list-item-content>
                </v-list-item>
              </v-sheet>
            </v-col>            
            <v-col
              sm="12"
              md="12"
              lg="3"
              xl="3"
            >
              <v-sheet
                class="mx-auto"
                elevation="8"
                rounded
              >
                <v-list-item
                  :active-class="getApptSetTextColor()"
                >
                  <v-list-item-content
                    @click="processApptSet()"
                  >
                    <template v-if="customer.status === 'Contacted' && customer.sub_status === 'Appointment Set'">
                      <v-btn
                        absolute
                        bottom
                        color="primary"
                        right
                        fab
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                    <v-list-item-icon>
                      <v-icon
                        x-large
                        :color="getApptSetIconColor()"
                        left
                      >
                        mdi-calendar-account
                      </v-icon>
                      <div class="display-2 font-weight-black text-center mt-2 ml-3">
                        Appointment Set
                      </div>
                    </v-list-item-icon>
                  </v-list-item-content>
                </v-list-item>
              </v-sheet>
            </v-col>
            <v-col
              sm="12"
              md="12"
              lg="2"
              xl="2"
            >
              <v-sheet
                class="mx-auto"
                elevation="8"
                rounded
              >
                <v-list-item active-class="secondary--text">
                  <v-list-item-content @click="lost()">
                    <template v-if="customer.sub_status === 'Lost'">
                      <v-btn
                        absolute
                        bottom
                        color="secondary"
                        right
                        fab
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                    <v-list-item-icon>
                      <v-icon
                        x-large
                        color="secondary"
                      >
                        mdi-emoticon-cry-outline
                      </v-icon>
                      <div class="display-2 font-weight-black text-center mt-2 ml-3">
                        Lost
                      </div>
                    </v-list-item-icon>
                  </v-list-item-content>
                </v-list-item>
              </v-sheet>
            </v-col>
          </v-row>
        </template>
      </v-list-item-group>
      <v-row dense>
        <v-col
          sm="12"
          md="12"
          lg="12"
          xl="12"
        >
          <base-material-card
            id="conversation"
            icon="mdi-chat"
            title="Conversation"
            color="secondary"
            dark
            style="background-color: #37474F"
          >
            <template v-slot:actions>
              <template v-if="crmAddress">
                <v-btn
                  color="primary"
                  class="ma-2 white--text"
                  :disabled="loading"
                  @click="showCrmDialog = true"
                >
                  Send to CRM
                  <v-icon
                    right
                    dark
                  >
                    mdi-cloud-upload
                  </v-icon>
                </v-btn>
              </template>
              <template v-if="!crmAddress">
                <v-alert
                  prominent
                  dense
                  type="error"
                >
                  <v-row align="center">
                    <v-col class="grsow">
                      You have not provided us a CRM email address to send leads to. Click the Settings button to set that now.
                    </v-col>
                    <v-col class="shrink">
                      <v-btn
                        @click="navigateToSettings()"
                      >
                        Settings
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </template>                       
            </template>

            <v-timeline>
              <v-timeline-item
                v-for="(event, i) in messages"
                :key="i"
                small
                :color="getTimelineColor(event.source)"
                :right="event.source === 'dealer' || event.source === 'info'"
                :left="event.source === 'customer'"
              >
                <template v-slot:opposite>
                  <span
                    :class="`headline font-weight-bold --text`"
                    v-text="getTimelineLabel(event)"
                  />
                </template>
                <v-card
                  class="elevation-2"
                  :color="getTimelineColor(event.source)"
                >
                  <v-card-text
                    :class="event.source === 'dealer' ? 'pt-2 mt-2 pb-0 mb-0 pl-1 ml-1 white--text' : 'pt-2 mt-2 pb-0 mb-0 pl-1 ml-1 black--text'"
                  >
                    {{ event.body }}
                  </v-card-text>
                  <div class="text-right pt-0 mt-0 pb-0 mb-0 pl-1 ml-1 pr-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle :class="event.source === 'dealer' ? 'display-1 font-weight-medium white--text' : 'display-1 font-weight-medium black--text'">
                          <em>{{ formatIsoDate(event.created_at, true) }}</em>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                  <template v-if="event.detected_language !== null && event.detected_language !== 'English'">
                    <p class="text-h4"><strong>Translation from <em>{{ event.detected_language }}</em></strong>: {{ event.translated_text }}</p>
                  </template>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          sm="12"
          md="12"
          lg="12"
          xl="12"
        >
          <v-card
            light
            max-width="100%"
            class="px-5 py-3"
            style="background-color: #FAFAFA"
          >
            <v-card-text class="text-center">
              <v-text-field
                ref="messageBox"
                v-model="message"
                color="secondary"
                label="Enter message to send to customer.."
                prepend-icon="mdi-chat"
                append-icon="mdi-car"
                :disabled="optedOut"
                clearable
                solo
                @click:append="loadVehicles()"
                @keyup.enter="sendTextMessage(null)"
              />
              <div class="text-right py-2">
                <v-btn
                  elevation="1"
                  color="primary"
                  :disabled="optedOut"
                  @click="sendTextMessage(null)"
                >
                  Send Message
                </v-btn>
              </div>
              <div class="pt-9">
                <v-textarea
                  v-model="comments"
                  label="Add internal comments for this customer. The customer will not see these comments. Comments are saved automatically."
                  outlined
                  rows="6"
                  prepend-icon="mdi-eye-off"
                  @blur="saveComments"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <template v-if="callLogs.length > 0">
        <v-row dense>
          <v-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >
            <base-material-card
              icon="mdi-phone"
              title="Calls"
              color="secondary"
              style="background-color: #FAFAFA"
            >
              <v-row>
                <v-col>
                  <v-card>
                    <v-data-table
                      :loading="loading"
                      loading-text="Loading... Please wait"
                      :headers="callLogHeaders"
                      :items="callLogs"
                      :items-per-page="50"
                      :sort-by="['created_at']"
                      hide-default-footer
                    >
                      <template #item.created_at="{value}">
                        {{ formatIsoDate(value, true) }}
                      </template>
                      <template #item.from="{value}">
                        {{ formatPhoneNumber(value, true) }}
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </base-material-card>
          </v-col>
        </v-row>
      </template>      
      <template v-if="queuedMessages.length > 0">
        <v-row dense>
          <v-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >
            <base-material-card
              icon="mdi-clock"
              title="Queued Messages"
              color="secondary"
              style="background-color: #FAFAFA"
            >
              <v-row>
                <v-col>
                  <v-card>
                    <v-data-table
                      :loading="loading"
                      loading-text="Loading... Please wait"
                      :headers="headers"
                      :items="queuedMessages"
                      :items-per-page="50"
                      :sort-by="['deliver_at']"
                      hide-default-footer
                    >
                      <template #item.deliver_at="{value}">
                        {{ formatIsoDate(value, true) }}
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          small
                          @click="deleteQueuedMessage(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </base-material-card>
          </v-col>
        </v-row>
      </template>
    </template>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="showVehicleDialog"
      width="700px"
      scrollable
    >
      <v-card
        class="mx-auto"
      >
        <v-toolbar
          color="#37474F"
          dark
        >
          <v-toolbar-title>Vehicles - Select a vehicle to send</v-toolbar-title>
        </v-toolbar>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          v-model="selectedVehicles"
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="vehicleHeaders"
          :items="vehicles"
          :search="search"
          :items-per-page="100"
          :sort-by="['make']"
          @click:row="vehicleSelected"
        >
          <template #item.id="{value, item}">
            <vehicle-top-line
              :vehicle="item"
              :photo="false"
              :linkphoto="false"
            />
          </template>
          <template #item.price="{value}">
            {{ formatPrice(value) }}
          </template>
          <template #item.mileage="{value}">
            {{ formatNumber(value) }}
          </template>
        </v-data-table>
        <v-card-actions>
          <v-btn
            @click="showVehicleDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showQueuedMessageDialog"
      hide-overlay
      persistent
      width="600"
    >
      <v-card
        color="orange darken-1"
        dark
      >
        <v-alert
          color="orange darken-1"
          dark
          icon="mdi-clock"
          border="left"
          prominent
        >
          {{ queuedMessage }}
          <router-link
            style="cursor: pointer; color: blue"
            :to="{ name: 'Settings' }"
          >
            Settings.
          </router-link>
          <div class="mt-2">
            If you want to go ahead and send the text now, click the Send Now button below.
          </div>
        </v-alert>
        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            @click="queueTextMessage()"
          >
            Queue Message
          </v-btn>
          <v-btn
            text
            @click="sendTextMessage('1')"
          >
            Send Now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showCrmDialog"
      persistent
      max-width="600"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Send to CRM
        </v-card-title>
        <v-row dense>
          <v-col>
            <div class="pt-9">
              <v-textarea
                v-model="comments"
                label="Add internal comments for this customer. The customer will not see these comments."
                outlined
                rows="6"
                prepend-icon="mdi-eye-off"
                @blur="saveComments"
              />
            </div>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="sendToCrm()"
          >
            Send to CRM
          </v-btn>
          <v-btn
            @click="showCrmDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showApptSetDialog"
      persistent
      max-width="600"
      @keydown.esc="showApptSetDialog = false"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Appointment Set
        </v-card-title>
        <v-row dense>
          <v-col>
            <div class="pt-9">
              <date-time
                v-model="apptDate"
                label="When is the appointment scheduled? Click to set the time."
                @changedApptDate="changedApptDate($event)"
              />
              <v-textarea
                v-model="apptComments"
                label="Add any comments about the appointment here."
                outlined
                rows="2"
              />
            </div>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="saveApptDate(this)"
          >
            Save
          </v-btn>
          <v-btn
            @click="showApptSetDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showClearApptSetDialog"
      persistent
      max-width="600"
      @keydown.esc="showClearApptSetDialog = false"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Clear Appointment Set?
        </v-card-title>
        <v-row dense>
          <v-col>
            <div class="pt-9">
              <v-alert
                color="#FFB001"
                dark
                icon="mdi-alert-decagram"
                border="left"
                prominent
              >
                <div class="display-1">
                  An appointment has already been recorded for this customer. Do you want to clear this appointment?
                </div>
              </v-alert>
            </div>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="clearApptDate()"
          >
            Yes
          </v-btn>
          <v-btn
            color="secondary"
            @click="showClearApptSetDialog = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showLostDialog"
      persistent
      max-width="600"
      @keydown.esc="showLostDialog = false"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Customer Lost
        </v-card-title>
        <v-row dense>
          <v-col>
            <div class="pt-9">
              <v-alert
                color="info"
                dark
                icon="mdi-alert-decagram"
                border="left"
                prominent
              >
                <div class="display-1">
                  You can set a "recycle date" for this customer so that they will be contacted again in the future. If you don't ever want to contact this customer again, just leave this field blank.
                </div>
              </v-alert>
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formattedRecycledDate"
                  label="Recycle Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="recycleDate"
                @input="startDateMenu = false"
              />
            </v-menu>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="setLost()()"
          >
            Lost
          </v-btn>
          <v-btn
            @click="showLostDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template v-if="customer">
      <v-dialog
        v-model="showCustomerInfoDialog"
        persistent
        @keydown.esc="showCustomerInfoDialog = false"
      >
        <v-card class="pl-2 pr-2">
          <v-card-title class="headline">
            &nbsp;
          </v-card-title>
          <v-row dense>
            <template v-if="customer.customer_type === 'Sales'">
              <v-col
                sm="6"
                md="6"
                lg="4"
                xl="4"
              >
                <base-material-card
                  title=""
                  color="#37474F"
                >
                  <template v-slot:heading>
                    <div class="display-2 font-weight-medium text-center">
                      <v-icon
                        large
                        color="primary"
                      >
                        mdi-account
                      </v-icon>
                      <span class="white--text">
                        Sales Customer
                      </span>
                    </div>
                  </template>
                  <template v-slot:actions>
                    <div class="display-1 font-weight-black">
                      <v-switch
                        v-model="optedOut"
                        label="Opted Out"
                        color="warning"
                      />
                    </div>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Name
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ customer.first_name }} {{ customer.last_name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Email
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ customer.email }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Phone
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ formatPhoneNumber(customer.phone) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Sale Type
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ customer.sale_type }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Deal Type
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ customer.deal_type }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Deal Number
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ customer.deal_number }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </base-material-card>
              </v-col>
            </template>
            <template v-if="customer.customer_type === 'Service'">
              <v-col
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <base-material-card
                  title=""
                  color="#37474F"
                >
                  <template v-slot:heading>
                    <div class="display-2 font-weight-medium text-center">
                      <v-icon
                        large
                        color="primary"
                      >
                        mdi-account
                      </v-icon>
                      <span class="white--text">
                        Service Customer
                      </span>
                    </div>
                  </template>
                  <template v-slot:actions>
                    <div class="display-1 font-weight-black">
                      <v-switch
                        v-model="optedOut"
                        label="Opted Out"
                        color="warning"
                      />
                    </div>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Name
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ customer.first_name }} {{ customer.last_name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Email
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ customer.email }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Phone
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ formatPhoneNumber(customer.phone) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        RO Number
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ customer.ro_number }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </base-material-card>
              </v-col>
            </template>            
            <template v-if="customer.customer_type === 'Sales'">
              <v-col
                sm="6"
                md="6"
                lg="4"
                xl="4"
              >
                <base-material-card
                  title=""
                  color="#37474F"
                >
                  <template v-slot:heading>
                    <div class="display-2 font-weight-medium text-center">
                      <v-icon
                        large
                        color="primary"
                      >
                        mdi-car
                      </v-icon>
                      <span class="white--text">
                        Sold Vehicle
                      </span>
                    </div>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Vehicle
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ customer.year }} {{ customer.make }} {{ customer.model }} {{ customer.trim }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Down Payment
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ formatPrice(customer.down_payment) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Monthly Payment
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ formatPrice(customer.monthly_payment) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        VIN
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ customer.vin }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Sold
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ formatIsoDate(customer.sold_at) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="display-1 font-weight-black"
                        style="height: 36px"
                      >
                          &nbsp;
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                          &nbsp;
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <template v-slot:actions>
                    <div
                      class="display-1 font-weight-black"
                      style="height: 66px"
                    />
                  </template>
                </base-material-card>
              </v-col>
            </template>
            <template v-if="customer.customer_type === 'Service'">
              <v-col
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <base-material-card
                  title=""
                  color="#37474F"
                >
                  <template v-slot:heading>
                    <div class="display-2 font-weight-medium text-center">
                      <v-icon
                        large
                        color="primary"
                      >
                        mdi-car
                      </v-icon>
                      <span class="white--text">
                        Recently Serviced Vehicle
                      </span>
                    </div>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Vehicle
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ customer.year }} {{ customer.make }} {{ customer.model }} {{ customer.trim }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        VIN
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ customer.vin }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Most recent RO close date
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ formatIsoDate(customer.ro_close_date) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Most recent RO customer sale amount
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ formatPriceNoZero(customer.customer_total_sale) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>                  
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="display-1 font-weight-black"
                        style="height: 36px"
                      >
                          &nbsp;
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                          &nbsp;
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <template v-slot:actions>
                    <div
                      class="display-1 font-weight-black"
                      style="height: 66px"
                    />
                  </template>
                </base-material-card>
              </v-col>
            </template>
            <template v-if="customer.customer_type === 'Sales'">       
              <v-col
                sm="6"
                md="6"
                lg="4"
                xl="4"
              >
                <base-material-card
                  title=""
                  color="#37474F"
                >
                  <template v-slot:heading>
                    <div class="display-2 font-weight-medium text-center">
                      <v-icon
                        large
                        color="primary"
                      >
                        mdi-currency-usd
                      </v-icon>
                      <span class="white--text">
                        Financing
                      </span>
                    </div>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Bank
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ customer.bank }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Term
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ customer.bank_term }} Months
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Term % Complete
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ customer.term_percent_complete }} %
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        Payments Remaining
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ customer.months_remaining }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="display-1 font-weight-black">
                        APR
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                        {{ customer.apr }} %
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="display-1 font-weight-black"
                        style="height: 36px"
                      >
                          &nbsp;
                      </v-list-item-title>
                      <v-list-item-subtitle class="display-1 font-weight-medium">
                          &nbsp;
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <template v-slot:actions>
                    <div
                      class="display-1 font-weight-black"
                      style="height: 66px"
                    />
                  </template>
                </base-material-card>
              </v-col>
            </template>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              @click="showCustomerInfoDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import ChatovateService from '@/services/ChatovateService.js'
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'
  import VehicleService from '@/services/VehicleService.js'
  import VehicleTopLine from '@/components/VehicleTopLine.vue'
  import titleMixin from '@/mixins/titleMixin.js'
  import DateTime from '@/components/DateTime.vue'

  export default {
    name: 'CustomerDetails',
    components: {
      VehicleTopLine,
      ErrorMessage,
      DateTime,
    },
    title: 'Attempting Contact',
    mixins: [formatterMixin, navigationMixin, titleMixin],
    data () {
      return {
        showErrorDialog: false,
        phases: [0, 1, 2, 3, 4],
        search: '',
        loading: true,
        loadingMessage: 'Loading',
        error: '',
        customerId: null,
        campaignId: null,
        customer: null,
        unreadMessages: false,
        numberUnreadMessages: 0,
        inScroll: false,
        message: null,
        optedOut: false,
        queuedMessages: [],
        callLogs: [],
        showVehicleDialog: false,
        showCrmDialog: false,
        showApptSetDialog: false,
        showClearApptSetDialog: false,
        showLostDialog: false,
        showCustomerInfoDialog: false,
        comments: [],
        apptDate: null,
        apptComments: null,
        crmAddress: null,
        headers: [
          { text: 'Message', value: 'message' },
          { text: 'Delivery Date', value: 'deliver_at', align: 'center' },
          { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
        ],
        callLogHeaders: [
          { text: 'From', value: 'from' },
          { text: 'Call Duration (in seconds)', value: 'call_duration', align: 'center' },
          { text: 'Received At', value: 'created_at', align: 'center' },
        ],        
        vehicleHeaders: [
          { text: 'ID', value: 'id', align: ' d-none' },
          { text: 'Vehicle', value: 'id' },
          { text: 'Mileage', value: 'mileage', align: 'center' },
          { text: 'Price', value: 'price', align: 'center' },
          { text: 'Year', value: 'year', align: ' d-none' },
          { text: 'Make', value: 'make', align: ' d-none' },
          { text: 'Model', value: 'model', align: ' d-none' },
          { text: 'Trim', value: 'trim', align: ' d-none' },
          { text: 'Exterior Color', value: 'exterior_color', align: ' d-none' },
          { text: 'Interior Color', value: 'interior_color', align: ' d-none' },
          { text: 'Fuel Type', value: 'fuel_type', align: ' d-none' },
          { text: 'Drive Train', value: 'drive_train', align: ' d-none' },
          { text: 'Stock', value: 'stock_number', align: ' d-none' },
          { text: '', value: 'data-table-expand' },
        ],
        messages: [],
        vehicles: [],
        selectedVehicles: [],
        showQueuedMessageDialog: false,
        queuedMessage: '',
        startDateMenu: false,
        recycleDate: new Date(new Date().setDate(new Date().getDate() + 365)).toISOString().substring(0, 10),
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName', 'chatovateSms', 'chatovateSentSms', 'chatovateInboundCall', 'user', 'apptsSet', 'apptsLost']),
      formattedRecycledDate:
        {
          get () {
            return this.formatDate(this.recycleDate)
          },
          set (value) {
            this.value = value
          },
        },
    },
    watch: {
      accountId () {
        this.loadCustomer()
        this.loadPhoneSettings()
      },
      chatovateInboundCall() {
        if (this.chatovateInboundCall.customer.uuid === this.customerId) {
          const newCall = { created_at: new Date().toISOString(), from: this.chatovateInboundCall.from, call_duration: this.chatovateInboundCall.call_duration }
          this.callLogs.push(newCall)
        }
      },

      chatovateSms () {
        if (this.chatovateSms.customer.uuid === this.customerId) {
          const newMessage = { body: this.chatovateSms.body, source: 'customer', created_at: new Date().toISOString(), translated_text: this.chatovateSms.translated_text, detected_language: this.chatovateSms.detected_language }
          this.messages.push(newMessage)
          this.numberUnreadMessages++
          this.unreadMessages = true
        }
        if (this.chatovateSms.body.toLowerCase().trim() === 'stop') {
          this.optedOut = true
        }
      },
      chatovateSentSms () {
        if (this.chatovateSentSms.customer.uuid === this.customerId) {
          const newMessage = { body: this.chatovateSentSms.message, source: this.chatovateSentSms.source, created_at: new Date().toISOString(), sender_name: this.chatovateSentSms.sender_name, translated_text: this.chatovateSentSms.translated_text, detected_language: this.chatovateSentSms.detected_language }
          this.messages.push(newMessage)
        }
      },
      optedOut () {
        ChatovateService.updateOptOut(this.campaignId, this.customerId, this.optedOut)
          .then(response => {
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
    },
    mounted () {
      this.scroll()
    },
    created () {
      this.customerId = this.$route.params.customer_id
      this.campaignId = this.$route.params.campaign_id
      this.loadCustomer()
      this.loadPhoneSettings()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadCustomer: function () {
        this.loadingMessage = 'Loading Customer Information'
        this.loading = true
        this.customer = null
        ChatovateService.getCustomer(this.campaignId, this.customerId)
          .then(response => {
            this.customer = response.data
            this.messages = this.customer.chatovate_message_logs
            this.numberUnreadMessages = this.customer.number_unread_sms
            this.unreadMessages = this.numberUnreadMessages > 0
            if (this.customer.opted_out_at) {
              this.optedOut = true
            }
            this.queuedMessages = this.customer.chatovate_message_queues
            this.callLogs = this.customer.chatovate_call_logs
            this.comments = this.customer.comments
            this.apptDate = this.customer.appt_date
            this.loading = false
            if (this.$route.query.show_conversation) {
              this.$nextTick(() => {
                this.$vuetify.goTo('#conversation')
              })
            }
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      loadPhoneSettings: function () {
        this.loadingMessage = 'Loading CRM Settings'
        this.loading = true
        ChatovateService.getChatovatePhoneSetting()
          .then(response => {
            this.crmAddress = response.data.crm_address
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      navigateToSettings: function() {
        this.$router.push({ name: 'Settings' })
      },
      getTimelineLabel: function (event) {
        const source = event.source
        if (source === 'dealer' || source === 'info') { return event.sender_name }
        if (source === 'customer') { return this.capitalizeFirstLetter(this.customer.first_name.toLowerCase()) + ' ' + this.capitalizeFirstLetter(this.customer.last_name.toLowerCase()) }
      },
      getTimelineColor: function (source) {
        if (source === 'dealer') { return '#3D84FC' }
        if (source === 'customer') { return '#90C159' }
        if (source === 'info') { return '#FFB001' }
      },
      sendTextMessage: function (force) {
        this.loadingMessage = 'Sending Message'
        this.loading = true
        ChatovateService.sendTextMessage(this.campaignId, this.customerId, this.message, force)
          .then(response => {
            this.loading = false
            if (response.status === 204) {
              this.queuedMessage = 'Your message cannot be sent because you have disabled texting. You can change this setting in the Equity Mining section under'
              this.showQueuedMessageDialog = true
            }
            if (response.status === 202) {
              this.queuedMessage = 'Your message was queued to be sent later, during the allowed texting hours. You can change the hours that texting is allowed in the Equity Mining section under'
              this.getQueuedMessages()
              this.showQueuedMessageDialog = true
            }
            if (response.status === 200) {
              this.showQueuedMessageDialog = false
              this.message = null
            }
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      queueTextMessage: function () {
        this.loadingMessage = 'Queueing Message'
        this.loading = true
        ChatovateService.queueTextMessage(this.campaignId, this.customerId, this.message)
          .then(response => {
            this.loading = false
            this.message = null
            this.getQueuedMessages()
            this.showQueuedMessageDialog = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      getQueuedMessages: function () {
        ChatovateService.getQueuedMessages(this.campaignId, this.customerId)
          .then(response => {
            this.queuedMessages = response.data
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      deleteQueuedMessage (item) {
        const index = this.queuedMessages.indexOf(item)
        if (confirm('Are you sure you want to delete this queued message?') && this.queuedMessages.splice(index, 1)) {
          this.loadingMessage = 'Deleting Queued Message'
          this.loading = true
          ChatovateService.deleteQueuedMessage(this.campaignId, this.customerId, item.id)
            .then(response => {
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        }
      },
      isScrolledIntoView: function (el) {
        const rect = el.getBoundingClientRect()
        const elemTop = rect.top
        const elemBottom = rect.bottom

        const isVisible = elemTop < window.innerHeight && elemBottom >= 0
        return isVisible
      },
      loadVehicles: function () {
        if (this.vehicles.length === 0) {
          this.loadingMessage = 'Loading Vehicles'
          this.loading = true
          VehicleService.getVehicles(this.filter)
            .then(response => {
              this.vehicles = response.data
              this.loading = false
              this.showVehicleDialog = true
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        } else {
          this.showVehicleDialog = true
        }
      },
      vehicleSelected: function (vehicle) {
        this.message = 'Check out this ' + vehicle.year + ' ' + vehicle.make + ' ' + vehicle.model + ' - ' + vehicle.vdp_url
        this.showVehicleDialog = false
      },
      sendToCrm () {
        this.loadingMessage = 'Sending to CRM'
        this.loading = true
        ChatovateService.sendToCrm(this.campaignId, this.customerId)
          .then(response => {
            this.customer.sent_to_crm_at = new Date()
            this.loading = false
            this.showCrmDialog = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      saveComments () {
        this.loadingMessage = 'Saving comments'
        this.loading = true
        ChatovateService.saveComments(this.campaignId, this.customerId, this.comments)
          .then(response => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      processApptSet () {
        if (this.customer.status === 'Contacted' && this.customer.sub_status === 'Appointment Set') {
          this.showClearApptSetDialog = true
        } else {
          this.showApptSetDialog = true
        }
      },
      saveApptDate () {
        this.loadingMessage = 'Saving appointment info'
        this.loading = true
        ChatovateService.saveApptDate(this.campaignId, this.customerId, this.apptDate, this.apptComments)
          .then(response => {
            this.loading = false
            this.showApptSetDialog = false
            this.customer.status = 'Contacted'
            this.customer.sub_status = 'Appointment Set'
            this.$store.dispatch('set_appts_set', parseInt(this.apptsSet) + 1)
          })
          .catch(error => {
            this.loading = false
            this.showApptSetDialog = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      clearApptDate () {
        this.loadingMessage = 'Clearing appointment info'
        this.loading = true
        ChatovateService.clearApptDate(this.campaignId, this.customerId)
          .then(response => {
            this.loading = false
            this.showClearApptSetDialog = false
            this.customer.status = 'Contacted'
            this.customer.sub_status = ''
            this.$store.dispatch('set_appts_set', parseInt(this.apptsSet) - 1)
          })
          .catch(error => {
            this.loading = false
            this.showClearApptSetDialog = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      lost () {
        if (this.customer.sub_status === 'Lost') {
          this.clearLost()
        } else {
          this.showLostDialog = true
        }
      },
      setLost () {
        this.loadingMessage = 'Setting Customer As Lost'
        this.loading = true
        var data = {
          campaignId: this.campaignId,
          customerId: this.customerId,
          recycleDate: this.recycleDate,
        }

        ChatovateService.setLost(data)
          .then(response => {
            this.loading = false
            this.customer.status = 'Contacted'
            this.customer.sub_status = 'Lost'
            this.$store.dispatch('set_appts_lost', parseInt(this.apptsLost) + 1)
            this.showLostDialog = false
          })
          .catch(error => {
            this.loading = false
            this.showLostDialog = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      clearLost () {
        this.loadingMessage = 'Clearing Customer As Lost'
        this.loading = true
        var data = {
          campaignId: this.campaignId,
          customerId: this.customerId,
        }
        ChatovateService.clearLost(data)
          .then(response => {
            this.loading = false
            this.customer.status = 'Contacted'
            this.customer.sub_status = ''
            this.$store.dispatch('set_appts_lost', parseInt(this.apptsLost) - 1)
          })
          .catch(error => {
            this.loading = false
            this.showClearApptSetDialog = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      getPageTitle () {
        if (this.customer) {
          return this.customer.first_name + ' ' + this.customer.last_name + ' - ' + this.customer.customer_type + ' Customer'
        }
      },
      getContactedIconColor () {
        if (this.customer.status === 'Contacted') {
          return 'primary'
        } else { return 'secondary' }
      },
      getContactedTextColor () {
        if (this.customer.status === 'Contacted') {
          return 'primary--text'
        } else { return 'secondary--text' }
      },
      getApptSetIconColor () {
        if (this.customer.status === 'Contacted' && this.customer.sub_status === 'Appointment Set') {
          return 'primary'
        } else { return 'secondary' }
      },
      getApptSetTextColor () {
        if (this.customer.status === 'Contacted' && this.customer.sub_status === 'Appointment Set') {
          return 'primary--text'
        } else { return 'secondary--text' }
      },
      getSentToCrmTextColor () {
        if (this.customer.sent_to_crm_at) {
          return 'primary--text'
        } else { return 'secondary--text' }
      },  
      getSentToCrmIconColor () {
        if (this.customer.sent_to_crm_at) {
          return 'primary'
        } else { return 'secondary' }
      },    
      changedApptDate (date) {
        this.apptDate = date
      },
      scroll: function () {
        window.onscroll = () => {
          const scrolledTo = document.querySelector('#conversation')

          if (scrolledTo && this.isScrolledIntoView(scrolledTo) && this.unreadMessages && !this.inScroll) {
            this.inScroll = true
            ChatovateService.markMessagesRead(this.campaignId, this.customerId)
              .then(response => {
                this.unreadMessages = false
                this.numberUnreadMessages = 0
                this.inScroll = false
              })
              .catch(error => {
                this.loading = false
                if (!error.response) {
                  this.error = 'Network Error. Check your internet connection.'
                } else {
                  if (error.response.status === 401) {
                    this.error = 'Unauthorized access.'
                  } else {
                    var errorMessage = error.response.data.error
                    this.error = errorMessage
                  }
                }
              })
          }
        }
      },
    },
  }
</script>
